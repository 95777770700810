@import 'constants';
@import 'mixins';

.vimeoBackground {
  display: flex;
  flex-direction: column;
}

.backgroundVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: calc($video-z-index);
  overflow: hidden;

  iframe {
    border: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }

  &__overflow {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: $video-overflow-z-index;
    background: linear-gradient(
      180deg,
      rgba(29, 25, 25, 0.6),
      rgba(29, 25, 25, 0.3),
      $rove-black 100%
    );
  }
}
