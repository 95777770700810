@import 'mixins';
@import 'constants';

.autoScrollableGallery {
  display: flex;
  overflow-x: auto;
  column-gap: 32px;
  padding-top: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media('<=laptop') {
    column-gap: 15px;
  }
}
