@import 'mixins';
@import 'constants';

.roveDifference {
  position: relative;
  color: $rove-black;
  padding: 96px 0 150px;
  margin-left: auto;
  width: 100%;
  background-image: url('/images/home-rove-difference-bg.png');
  background-size: 100% 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @include media('<=laptop') {
    display: none;
  }

  &__title {
    margin: 0;
    font-size: 32px;
    font-weight: 300;
    line-height: 48px;
    text-align: center;
  }

  &__description {
    margin-bottom: 48px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    max-width: 642px;
  }

  &__slider {
    padding-bottom: 47px;
  }
}

.roveDifferenceCard {
  padding: 8px;
  min-width: min(500px, 35%);
  width: min(500px, 35%);
  background: $rove-white;
  border-radius: 16px;
  overflow: hidden;
  transition: 0.2s linear all;
  height: max-content;

  &:hover {
    transform: scale(1.05);
  }

  &:first-of-type {
    margin-left: 10vw;
  }

  &:last-of-type {
    margin-right: 10vw;
  }

  &__image {
    margin-bottom: 16px;
    aspect-ratio: 1.25;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  &__footer {
    padding: 8px 16px 16px;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: change-color($color: $rove-dark-black, $alpha: 0.6);
  }
}
