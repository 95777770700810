@import 'constants';
@import 'mixins';

.tabs {
  display: flex;
  align-items: center;
  column-gap: max(3vw, 30px);
  row-gap: 16px;
  overflow: auto;
  font-size: 16px;
  line-height: 32px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__tab {
    padding: 0 4px;
    font-size: inherit;
    line-height: inherit;
    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    transition: 0.2s linear all;
    cursor: pointer;

    &.default {
      color: $rove-white;
      border-bottom: 3px solid transparent;

      &:hover {
        border-bottom: 3px solid $rove-white;
      }

      &.selected {
        border-bottom: 3px solid $rove-blue;
        font-weight: 600;
      }
    }

    &.light,
    &.white {
      color: $rove-black;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid $rove-blue;
      }

      &.selected {
        border-bottom: 1px solid $rove-black;
      }
    }
  }
}
