@import 'mixins';
@import 'constants';

.featuredProperties {
  margin-top: 152px;
  padding: 0 $desktop-page-indent 32px;
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: flex-end;
  transition: 0.2s linear all;
  position: relative;

  @include media('<=laptop') {
    margin-top: 82px;
    padding: 0 $mobile-page-indent 21px;
    scroll-snap-type: y;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
    background: linear-gradient(
      180deg,
      #1d1919 0%,
      rgba(29, 25, 25, 0.75) 2.77%,
      rgba(29, 25, 25, 0) 100%
    );
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;
    width: 100%;

    @include media('<=laptop') {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  .dotsBlock {
    background: #00000033;
    border-radius: 38px;
    padding: 4px 6px;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;

    &__dot {
      min-width: 14px;
      width: 14px;
      height: 14px;
      opacity: 0.4;
      border-radius: 50%;
      background: $rove-white;
      cursor: pointer;
      transition: 0.2s linear all;

      &:hover {
        transform: scale(1.1);
      }
    }

    &__dotActive {
      opacity: 1;
    }
  }

  .discoverBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: min(40vw, 100%);
    padding: 8px 16px 8px 16px;
    gap: 29px;
    border-radius: 54px;
    background: $rove-white;

    @include media('<=laptop') {
      min-width: 100%;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 22px;
      gap: 12px;
    }

    &__title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: $rove-black;
    }
  }
}
