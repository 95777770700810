@import 'constants';
@import 'mixins';

.listingCard {
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  border-radius: 10px;

  &__body {
    width: 100%;
  }

  &__imageContainer {
    border-radius: 8px;
    overflow: hidden;
  }

  &__title {
    margin-top: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__rooms {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 14px;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;

    svg {
      margin-right: 4px;
    }
  }
}
