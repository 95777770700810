@import 'constants';
@import 'mixins';

.imageWithLoader {
  position: relative;
  overflow: hidden;

  img {
    object-fit: cover;
  }
}
