@import 'constants';
@import 'mixins';

.listingsByMarkets {
  position: relative;
  z-index: 3;
  padding-bottom: 39px;

  &__tabs {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;

    @include media('<=laptop') {
      justify-content: flex-start;
      padding: 0 16px;
    }
  }

  &__properties {
    padding: 0 $desktop-page-indent 42px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media('<=laptop') {
      grid-template-columns: repeat(3, 1fr);
      padding: 0 $mobile-page-indent 42px;
    }

    @include media('<=tablet') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media('<=phone') {
      grid-template-columns: 1fr;
    }
  }

  &__button {
    margin: 0 auto;
    width: 121px;
    height: 37px !important;
    min-height: 37px !important;
  }
}
