@import 'mixins';
@import 'constants';

.guestAppPreview {
  padding: 0 $desktop-page-indent 148px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  @include media('<=laptop') {
    padding: 0 $mobile-page-indent 220px;
  }

  &__textBlock {
    max-width: 642px;
    width: 100%;
    margin: 0 0 36px 105px;

    @include media('<=laptop') {
      margin: 0 0 36px;
    }
  }

  &__title {
    margin: 0;
    font-size: 32px;
    font-weight: 275;
    line-height: 48px;
    text-align: left;

    @include media('<=laptop') {
      margin-bottom: 8px;
      font-size: 28px;
      line-height: 33.6px;
    }
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  &__images {
    position: relative;
  }

  &__image {
    &__search {
      width: 851px;
      height: 530px;

      @include media('<=laptop') {
        width: 100%;
        height: auto;
        aspect-ratio: 851 / 530;
      }
    }

    &__listing {
      width: 525px;
      height: 530px;
      position: absolute;
      top: 52px;
      left: 732px;

      @include media('<=laptop') {
        width: 63%;
        height: auto;
        aspect-ratio: 525 / 530;
        top: 118px;
        left: 106px;
      }
    }
  }
}
