@import 'mixins';
@import 'constants';

.threeFeaturedProperties {
  padding: 0 $desktop-page-indent 96px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  @include media('<=laptop') {
    padding: 0 $mobile-page-indent 96px;
  }

  &__textBlock {
    max-width: 642px;
    width: 100%;
    margin: 0 auto 48px;
  }

  &__title {
    margin: 0;
    font-size: 32px;
    font-weight: 275;
    line-height: 48px;
    text-align: left;

    @include media('<=laptop') {
      margin-bottom: 8px;
      font-size: 28px;
      line-height: 33.6px;
    }
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;

    @include media('<=laptop') {
      grid-template-columns: 1fr;
    }
  }

  &__listing {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: calc(100vw - $mobile-page-indent * 2);

    &:first-of-type {
      grid-row-start: 1;
      grid-row-end: 3;

      .listingImage {
        aspect-ratio: 0.9;
      }

      @include media('<=laptop') {
        grid-row-start: unset;
        grid-row-end: unset;

        .listingImage {
          aspect-ratio: 3 / 2;
        }
      }
    }

    .listingImage {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      max-width: 100%;

      img {
        transition: 0.2s linear all;
      }

      &:hover {
        img {
          transform: scale(1.02);
        }
      }

      &__image {
        width: 100%;
        height: 100%;
      }

      @include media('<=laptop') {
        aspect-ratio: 3 / 2;
      }
    }

    .listingFooter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      @include media('<=laptop') {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
      }

      &__title {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $rove-white;

        @include media('<=laptop') {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}
