@import 'constants';
@import 'mixins';

.discoverLink {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 275;
  line-height: 24px;

  @include media('<=laptop') {
    font-size: 14px;
    line-height: 16px;
  }

  &__dark {
    color: $rove-white;
  }

  &__light {
    color: $rove-black;
  }
}
