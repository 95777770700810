@import 'mixins';
@import 'constants';

$dayWidth: 44px;

.disabled {
  background-color: #ececec;
}

.container {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  justify-content: center;
  position: relative;
  align-items: normal;

  @include media('<=laptop') {
    padding: 1vh 2vw 2vh 2vw;
    flex-direction: column;
    border: 0.5px solid $rove-green;
    align-items: flex-start;
    background-color: change-color($color: #ffffff, $alpha: 0.5);
  }

  .icon {
    margin-right: -20px;
  }

  .placeholder {
    font-size: 12px;
    color: $rove-old-tan;
    font-weight: 300;
  }

  .button {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    height: 100%;

    @include media('<=laptop') {
      justify-content: start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      padding: 1vh 2vw 0 2vw;
    }

    &:hover {
      cursor: pointer;
    }

    .label {
      display: flex;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 1vh;
    }

    .dates {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
    }

    .dateButton {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 1vw;
      min-width: 107px;
      justify-content: center;
      border-radius: 35px;
    }

    .borderButton {
      border: 1px solid $rove-old-tan;
      box-shadow: 0 0 4px 0 rgba(231, 231, 231, 0.25),
        0 0 25px 0 rgba(217, 217, 217, 0.25);
      background-color: transparent;

      &.active {
        border: 1px solid $rove-blue;
      }
    }
  }
}

.rangePicker {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: $calendar-z-index;
  width: max-content;
  padding: 18px;
  background-color: $rove-white;
  box-shadow: 0 10px 10px 10px rgba(182, 182, 182, 0.25);
  border: 1px solid $rove-black;
  border-radius: 8px;

  @include media('<=laptop') {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header {
    margin: 40px 0 12px;
    display: flex;
    justify-content: space-between;

    &__arrow {
      transition: 0.2s linear all;
      cursor: pointer;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .body {
    margin-bottom: 12px;
    display: flex;
    column-gap: $dayWidth;
    user-select: none;

    @include media('<=laptop') {
      overflow: visible;
    }

    .monthWrapper {
      position: relative;

      @include media('<=laptop') {
        margin: 0 auto;
      }
    }

    .monthName {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      position: absolute;
      top: -32px;
      left: 50%;
      transform: translateX(-50%);
    }

    .week {
      display: flex;
      justify-content: center;
      margin-bottom: 4px;

      .day {
        width: $dayWidth;
        height: 13px;
        text-align: center;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
      }
    }

    .list {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: y mandatory;
      flex-flow: row nowrap;
      overflow-y: hidden;
      transition: 0.1s all linear;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 8px;

    @include media('<=laptop') {
      margin-top: 16px;
    }

    &__actionButton {
      width: 67px;
      height: 38px;
      min-height: 38px;
      border-radius: 8px;

      @include media('<=laptop') {
        width: 100%;
      }
    }

    &__clearButton {
      width: 103px;
      height: 38px;
      min-height: 38px;
      border-radius: 8px;

      @include media('<=laptop') {
        width: 100%;
      }
    }
  }
}

.month {
  height: max-content;
  padding: 1px;

  .days {
    display: grid;
    grid-template-columns: repeat(7, $dayWidth);
    grid-template-areas: 'day day day day day day day';
    justify-content: center;
    align-items: center;
  }
}

.dayInMonth {
  width: $dayWidth;
  height: $dayWidth;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $rove-white;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  line-height: 1;
  color: $rove-dark-tan;

  &__withOutline {
    outline: 1px solid $rove-dark-tan;
  }

  &__today {
    font-weight: 600;
  }

  &__disabled {
    background-color: rgba(143, 139, 131, 0.5);
  }

  &__selected {
    background-color: #d1fbf9;
  }

  &__firstOrLastInInterval {
    background-color: $rove-light-blue;
    color: $rove-white;
  }

  &__fuzzyRange {
    background-color: $rove-blue;
  }
}

.fuzzyDates {
  margin: 14px 0;

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 14px 18px;
    gap: 9px;
    border-top: 1px solid $rove-off-white;
    width: calc(100% + 36px);
    margin-left: -18px;

    &:last-of-type {
      border-bottom: 1px solid $rove-off-white;
    }
  }

  &__label {
    width: 62px;
    color: $rove-dark-tan;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;

    @include media('<=laptop') {
      width: 100%;
    }
  }

  &__option {
    padding: 6px 12px;
    color: $rove-dark-tan;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    border-radius: 24px;
    border: 1px solid $rove-dark-tan;
    cursor: pointer;
    transition: 0.2s linear all;

    &:hover {
      transform: scale(1.05);
    }

    &__selected {
      background-color: $rove-dark-tan;
      color: $rove-white;
    }
  }
}
