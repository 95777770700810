@import 'mixins';
@import 'constants';

.pressReviews {
  padding: 0 $desktop-page-indent;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media('<=laptop') {
    padding: 0 $mobile-page-indent;
  }

  &__title {
    margin: 0 0 16px;
    font-size: 32px;
    font-weight: 275;
    line-height: 48px;
    text-align: left;

    @include media('<=laptop') {
      font-size: 28px;
      line-height: 33.6px;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5vw;

    @include media('<=tablet') {
      grid-template-columns: 1fr;
      gap: 48px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    row-gap: 32px;

    @include media('<=tablet') {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }

  &__itemText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  &__itemLogo {
    @include media('<=tablet') {
      max-height: 22px;
    }
  }
}
