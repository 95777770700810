@import 'constants';
@import 'mixins';

.availableFrom {
  color: $rove-black;
  white-space: nowrap;
  background: $rove-white;
  border-radius: 5px;
  width: max-content;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;

  strong {
    font-weight: 600;
  }
}
