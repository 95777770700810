@import 'constants';
@import 'mixins';

.carouselWrapper {
  position: relative;

  & > div {
    width: 100%;
    height: 100%;
  }

  &:hover {
    .arrowOnHover {
      display: flex;
    }
  }

  .image {
    aspect-ratio: 1.5;

    @include media('<=laptop') {
      aspect-ratio: 1.7;
    }

    img {
      transition: 0.2s linear all;
    }

    &:hover {
      img {
        transform: scale(1.03);
      }
    }
  }

  .availableFrom {
    position: absolute;
    top: 11px;
    left: 11px;
    z-index: 1;
  }

  .rating {
    position: absolute;
    top: 0;
    right: 20px;
    width: 79px;
    height: 24px;
    z-index: 4;
    border-radius: 0 0 8px 8px;
    border: 1px solid $rove-old-tan;
    background: $rove-white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .dotsWrapper {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    height: 12px;
    overflow-x: hidden;

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      width: max-content;
      height: 100%;
      overflow-x: auto;
      scrollbar-width: none;
    }

    .dot {
      border-radius: 50%;
      transition: 0.2s linear all;
      background-color: $rove-white;
      min-width: 4px;
      width: 4px;
      height: 4px;
      opacity: 0.6;

      &.active {
        opacity: 1;
        background: rgba(248, 244, 241, 1);
      }

      &.active,
      &.inProximity {
        min-width: 6px;
        width: 6px;
        height: 6px;
      }
    }
  }

  .arrowOnHover {
    position: absolute;
    z-index: 3;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: transparent;
    padding: 0;
    border: 0;
    margin: 0;
    transition: 0.2s linear all;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }

    &:first-of-type {
      left: 12px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        transform: rotate(180deg);
      }
    }

    &:last-of-type {
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
