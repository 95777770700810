@import 'mixins';
@import 'constants';

.hero {
  height: 541px;
  position: relative;

  @include media('<=laptop') {
    padding: 0 16px;
  }

  &__wrapper {
    z-index: $text-over-video-z-index;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    margin: 0;
    font-size: 48px;
    font-weight: 275;
    line-height: 72px;
    text-align: center;
  }

  &__subtitle {
    margin: 0 0 32px;
    text-align: center;
    max-width: 642px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
