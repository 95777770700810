@import 'constants';
@import 'mixins';

.home {
  background-color: $rove-black;
  color: $rove-white;

  &__textOverflow {
    z-index: $text-over-video-z-index;
    position: absolute;
    top: 20vh;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    max-width: 80vw;
    font-weight: 400;
    font-size: 50px;
    line-height: 80px;
    display: flex;
    align-items: center;

    @include media('<=laptop') {
      left: 21px;
      max-width: unset;
      transform: unset;
      font-size: 40px;
      line-height: 50px;
    }
  }
}
