@import 'constants';
@import 'mixins';

.heroSearch {
  width: min(647px, 100%);
  min-width: min(647px, 100%);
  display: flex;
  align-items: center;
  height: 53px;
  min-height: 53px;
  background: $rove-white;
  border: 1px solid $rove-dark-black;
  border-radius: 41px;
  position: relative;
  transition: 0.7s linear all;
  padding: 0 8.5px 0 32px;

  @include media('<=laptop') {
    height: 45px;
    min-height: 45px;
    padding: 0 4px 0 16px;
  }

  &__marketsDropdownButton {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $rove-dark-black;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  &__desktopWord {
    display: block;
    @include media('<=laptop') {
      display: none;
    }
  }

  &__marketsDropdown {
    margin-top: 4px;
    background-color: $rove-white;
    color: $rove-dark-black;
    border-radius: 8px;
  }

  &__dateRangeContainer {
    margin-right: 24px;
    height: 100%;

    @include media('<=laptop') {
      padding: 0 !important;
      margin: 0 10px !important;
      border: none !important;
      background: unset !important;
      width: 100% !important;
    }

    & > div {
      @include media('<=laptop') {
        display: block;
        padding: 0 !important;
        flex-direction: row !important;
        column-gap: 0 !important;
      }

      & > div {
        padding: 0 !important;
        color: $rove-dark-black;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        border-radius: 0 !important;

        @include media('<=laptop') {
          min-width: 22vw !important;
        }

        &:first-of-type {
          border-left: 1px solid #e3e3e3;
        }

        &:last-of-type {
          border-left: 1px solid #e3e3e3;
          border-right: 1px solid #e3e3e3;
        }
      }

      & > svg {
        display: none;
      }
    }
  }

  &__dateRangeCalendar {
    margin-top: 5px;
    background-color: $rove-white !important;
    color: $rove-dark-black;
    border: none !important;
    border-radius: 20px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 60px !important;
  }

  &__searchButton {
    min-width: 98px;
    width: 98px;
    height: 37px !important;
    min-height: 37px !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;

    @include media('<=laptop') {
      display: none;
    }
  }

  &__arrowButton {
    display: none;
    min-width: 37px;
    width: 37px;
    height: 37px;
    transition: transform 0.2s linear;

    &:hover,
    &:focus {
      transform: scale(1.1);
    }

    @include media('<=laptop') {
      display: block;
    }
  }
}
